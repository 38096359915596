import React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"

import Layout from "../../../components/layout"
import Slider from "../../../components/slider"

const Media = ({ data, location }) => {
  const posts = data.allMediaYaml.edges

  const title = "Média megjelenés"
  const subtitle = "AURA a médiában"

  return (
    <Layout location={location} title={title} description={subtitle}>

      <Slider title={title} subtitle={subtitle}/>

      <Container>
        {posts.map(({ node }) => {
          return (
            <article>
              <header>
                <h5>
                  <a href={node.media.url}>{node.media.title}</a>
                </h5>
              </header>
              <section>
                <p>{node.media.description}</p>
                <small>
                  {node.media.date}, {node.media.author}
                </small>
              </section>
              <hr/>
            </article>
          )
        })}
      </Container>
    </Layout>
  )
}

export default Media

export const pageQuery = graphql`
  query {
    allMediaYaml(sort: { fields: [media___date], order: DESC }) {
      edges {
        node {
          media {
            author
            date
            description
            language
            title
            url
          }
        }
      }
    }
  }
`
